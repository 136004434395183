import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tabs,
  Switch,
  Alert,
  Divider,
  Checkbox,
  Tooltip
} from 'antd';
import ResCol from 'components/forms/ResCol';
import {
  DrawerEdit,
  SearchValue,
  FormItemWrap,
  ResourceEdit,
  useAuthContext,
  useCrudEditContext,
  api,
  normalizeToSelect,
  normalizeFromSelect,
  RemoteSelectCrud
} from 'react-structure-admin';

import {
  closeSettings,
  getServiceApproximateTaxes,
  updateSettings
} from 'stores/companies/companyActions';
import { useDispatch } from 'react-redux';
import InputPercent from 'components/InputPercent';
import {
  AddressForForeign,
  AddressV2,
  CfopSelect,
  InputNumeric,
  TooltipInfoIcon
} from 'components';
import ProductUnitOfMeasurement from 'pages/app/products/ProductUnitOfMeasurement';
import {
  APPROXIMATETAXES_COMPLETE,
  APPROXIMATETAXES_DISABLED,
  APPROXIMATETAXES_SIMPLIFIED,
  display,
  issCalculationModesDisplay
} from 'constants/types';
import ObjectHelper from 'util/helpers/ObjectHelper';
import {
  cityNormalizeFromSelect,
  cityNormalizeToSelect
} from 'util/normalizers';
import TaxationGroupEdit from 'pages/app/taxationGroups/TaxationGroupEdit';
import SelectTaxationType from 'components/SelectTaxationType';
import { InfoCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const issueTypeDescriptions = {
  normal: 'Web Service Município',
  website: 'Site/Portal',
  annfs: 'API Ambiente Nacional',
  alt: 'Web Service Município (Alternativo)'
};

const CompanySettings = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const { company, isAdmin } = useAuthContext();
  const { form } = useCrudEditContext();
  const { affiliate, settings } = data;
  const [selectedOption, setSelectedOption] = useState(
    data.settings.addressResolutionMode
  );
  const [affiliateEnabled, setAffiliateEnabled] = useState(
    affiliate.enabledAffiliateInvoices
  );

  const [environmentType, setEnvironmentType] = useState(
    settings.serviceInvoice.environmentType
  );

  const [autoIssueInvoice, setAutoIssueInvoice] = useState(
    affiliate.autoIssueInvoice
  );

  const [issueType, setIssueType] = useState(settings.serviceInvoice.issueType);

  const [providerSettings, setProviderSettings] = useState(
    data.providerSettings ?? {}
  );

  const [issueTypes, setIssueTypes] = useState([]);

  const [approximateTaxes, setApproximateTaxes] = useState(
    settings.serviceInvoice.approximateTaxes
  );

  const [tab, setTab] = useState('producer');

  const [checkedCalculationMode, setCheckedCalculationMode] = useState(
    settings?.serviceInvoice.issCalculationMode ?? []
  );

  useEffect(() => {
    form.validateFields(['nextNumber', 'series', 'nextBatchNumber']);
  }, [affiliateEnabled]);

  useEffect(() => {
    api
      .fetch('companies/:companyId/issue-types', {
        companyId: company.id,
        model: 'serviceInvoice',
        environmentType
      })
      .then(({ data }) => {
        setIssueTypes(data.result);
        if (!data.result.includes(issueType)) {
          setIssueType(null);
          form.setFieldsValue({
            settings: { serviceInvoice: { issueType: null } }
          });
        }
      });
  }, [environmentType]);

  useEffect(() => {
    if (issueType) {
      api
        .fetch('finance-offices/settings/', {
          issueType
        })
        .then(({ data }) => {
          setProviderSettings({
            ...data.result.serviceInvoice.providerSettings
          });
        });
    }
  }, [issueType]);

  const onAfterUpdate = () => {
    message.success('Registro salvo com sucesso.');
  };

  const normalizeProfileSettings = (
    { serviceInvoice, productInvoice },
    factory
  ) => {
    return {
      serviceInvoice: {
        ...serviceInvoice,
        taxationGroup:
          serviceInvoice != null ? factory(serviceInvoice.taxationGroup) : null
      },
      productInvoice: {
        ...productInvoice,
        taxationGroup:
          productInvoice != null ? factory(productInvoice.taxationGroup) : null
      }
    };
  };

  const normalizeCountryFromSelect = (country) => {
    if (!country || selectedOption !== 'useForeignAddress') {
      return { code: 1058, name: 'Brasil' };
    }

    return { code: country.value, name: country.label };
  };

  const normalizeCountryToSelect = (country) => {
    if (!country) {
      return null;
    }

    return { value: country.code, label: country.name };
  };

  const handleSubmit = (formData) => {
    const { settings, producer, affiliate } = data;
    const {
      producer: newProducer,
      affiliate: newAffiliate,
      settings: newSettings
    } = formData;
    const { defaultAddress = {}, serviceInvoice = {} } = settings;
    const {
      defaultAddress: newDefaultAddress = {},
      serviceInvoice: newServiceInvoice = {}
    } = newSettings;

    dispatch(
      updateSettings(
        {
          id: company.id,
          ...formData,
          producer: {
            ...producer,
            ...newProducer,
            ...normalizeProfileSettings(newProducer, normalizeFromSelect)
          },
          affiliate: {
            ...affiliate,
            ...newAffiliate,
            ...normalizeProfileSettings(newAffiliate, normalizeFromSelect)
          },
          settings: {
            ...settings,
            ...newSettings,
            serviceInvoice: { ...serviceInvoice, ...newServiceInvoice },
            defaultAddress:
              Object.keys(newDefaultAddress).length > 0
                ? {
                    ...(defaultAddress || {}),
                    ...newDefaultAddress,
                    city:
                      selectedOption === 'useForeignAddress'
                        ? null
                        : newDefaultAddress.city
                        ? cityNormalizeFromSelect(newDefaultAddress.city)
                        : defaultAddress.city,
                    country:
                      newDefaultAddress.country ||
                      selectedOption !== 'useForeignAddress'
                        ? normalizeCountryFromSelect(newDefaultAddress.country)
                        : defaultAddress.country
                  }
                : defaultAddress
          }
        },
        onAfterUpdate
      )
    );
  };

  const handleCancel = () => {
    dispatch(closeSettings());
  };

  const handleAutoIssueInvoice = (value) => {
    setAutoIssueInvoice(value);
  };

  const handleChangeAffiliateEnabled = (value) => {
    setAffiliateEnabled(value);
  };

  const getApproximateTaxesIbpt = (taxationGroupId) => {
    dispatch(
      getServiceApproximateTaxes(taxationGroupId, ({ data: { result } }) => {
        const {
          nationalTax,
          municipalTax,
          stateTax,
          importTax,
          expiresAt,
          version
        } = result;

        form.setFieldsValue({
          settings: {
            serviceInvoice: {
              approximateTaxes: {
                national: nationalTax,
                municipal: municipalTax,
                state: stateTax,
                imported: importTax,
                expiresAt,
                version
              }
            }
          }
        });
      })
    );
  };

  const handleChangedApproximateTaxes = (
    changedApproximateTaxes,
    allValues
  ) => {
    const { federalServiceCode } = allValues?.producer?.serviceInvoice ?? {};
    setApproximateTaxes(allValues?.settings?.serviceInvoice?.approximateTaxes);

    if (changedApproximateTaxes?.useIbpt === false) {
      form.setFieldsValue({
        settings: {
          serviceInvoice: {
            approximateTaxes: {
              national: 0,
              municipal: 0,
              state: 0,
              imported: 0,
              expiresAt: null,
              version: null
            }
          }
        }
      });
    }

    if (federalServiceCode && changedApproximateTaxes?.useIbpt === true) {
      getApproximateTaxesIbpt(federalServiceCode);
    }
  };

  const handleChangedFederalServiceCode = (
    changedFederalServiceCode,
    allValues
  ) => {
    if (
      changedFederalServiceCode &&
      allValues?.settings?.serviceInvoice?.approximateTaxes?.useIbpt === true &&
      changedFederalServiceCode.length >= 4
    ) {
      getApproximateTaxesIbpt(changedFederalServiceCode);
    }
  };

  const handleChangedValues = (changedValues, allValues) => {
    const { settings, producer } = changedValues;

    if (settings?.serviceInvoice?.environmentType) {
      setEnvironmentType(settings.serviceInvoice.environmentType);
    }

    if (settings?.serviceInvoice?.issueType) {
      setIssueType(settings.serviceInvoice.issueType);
    }

    if (ObjectHelper.hasProperty(settings?.serviceInvoice?.approximateTaxes)) {
      handleChangedApproximateTaxes(
        settings.serviceInvoice.approximateTaxes,
        allValues
      );
    }

    if (producer?.serviceInvoice?.federalServiceCode !== undefined) {
      handleChangedFederalServiceCode(
        producer?.serviceInvoice?.federalServiceCode,
        allValues
      );
    }
  };

  const handleBeforeBinding = (data) => {
    const { settings } = data;
    const defaultAddress = settings.defaultAddress || {};

    return {
      ...data,
      producer: {
        ...data.producer,
        ...normalizeProfileSettings(data.producer, normalizeToSelect)
      },
      affiliate: {
        ...data.affiliate,
        ...normalizeProfileSettings(data.affiliate, normalizeToSelect)
      },
      settings: {
        ...settings,
        defaultAddress: {
          ...defaultAddress,
          city: cityNormalizeToSelect(defaultAddress.city),
          country: normalizeCountryToSelect(defaultAddress.country)
        }
      }
    };
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const supportedIssCalculationModes = [
    'suppressIssAmountOnSimplesNacional',
    'requiresIssBaseTax'
  ];
  const issCalculationModes = supportedIssCalculationModes.map((type) => ({
    label: issCalculationModesDisplay[type],
    value: type
  }));

  const handleChangeTabs = (key) => {
    setTab(key);
  };

  const handleChangeCalculationMode = (selectedValues) => {
    setCheckedCalculationMode(selectedValues);
  };

  return (
    <DrawerEdit
      {...rest}
      data={data}
      onSubmit={handleSubmit}
      onBeforeBinding={handleBeforeBinding}
      cancelEdit={handleCancel}
      onAfterUpdate={onAfterUpdate}
      onValuesChange={handleChangedValues}
      updateFormOnDataChanged={false}
      size="md"
      title="Configurações fiscais"
    >
      <Form layout="vertical">
        <Tabs>
          {!company.useCombinedInvoice && (
            <TabPane tab="NFS-e" key="nfse" forceRender>
              <Row>
                <ResCol span={data.settings.serviceInvoice.useRps ? 12 : 8}>
                  <FormItemWrap
                    name={['settings', 'serviceInvoice', 'environmentType']}
                    label="Ambiente"
                  >
                    <Select>
                      <Select.Option value="production">Produção</Select.Option>
                      <Select.Option value="development">
                        Homologação
                      </Select.Option>
                    </Select>
                  </FormItemWrap>
                </ResCol>
                <ResCol span={data.settings.serviceInvoice.useRps ? 12 : 8}>
                  <FormItemWrap
                    name={['settings', 'serviceInvoice', 'issueType']}
                    label="Método de emissão"
                  >
                    <Select>
                      {issueTypes.map((c) => (
                        <Select.Option value={c}>
                          {issueTypeDescriptions[c]}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItemWrap>
                </ResCol>

                {providerSettings.provider !== 'softplan' &&
                (providerSettings.useInvoiceNumber ||
                  providerSettings.useRps) ? (
                  <ResCol span={8}>
                    <FormItemWrap
                      name={['settings', 'serviceInvoice', 'nextNumber']}
                      label={
                        data.settings.serviceInvoice.useRps
                          ? 'Número do próximo RPS'
                          : 'Número da próxima NFS-e'
                      }
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </FormItemWrap>
                  </ResCol>
                ) : null}

                {providerSettings.provider === 'softplan' ? (
                  <ResCol span={8}>
                    <FormItemWrap
                      name={['settings', 'serviceInvoice', 'authNumber']}
                      label="AEDF-e"
                    >
                      <InputNumeric style={{ width: '100%' }} maxLength={11} />
                    </FormItemWrap>
                  </ResCol>
                ) : null}

                {data.settings.serviceInvoice.useBatchNumber && (
                  <ResCol span={8}>
                    <FormItemWrap
                      name={['settings', 'serviceInvoice', 'nextBatchNumber']}
                      label="Número do próximo Lote"
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        max={99999999999}
                      />
                    </FormItemWrap>
                  </ResCol>
                )}
                {data.settings.serviceInvoice.useSeries && (
                  <ResCol span={8}>
                    <FormItemWrap
                      name={['settings', 'serviceInvoice', 'series']}
                      label={
                        data.settings.serviceInvoice.useRps
                          ? 'Série do RPS'
                          : 'Série'
                      }
                    >
                      <Input maxLength={10} />
                    </FormItemWrap>
                  </ResCol>
                )}
                {providerSettings.authentication === 'userName' && (
                  <>
                    <ResCol span={8}>
                      <FormItemWrap
                        label="Usuário"
                        name={['settings', 'serviceInvoice', 'userName']}
                        required
                      >
                        <Input maxLength={100} />
                      </FormItemWrap>
                    </ResCol>
                    <ResCol span={8}>
                      <FormItemWrap
                        label="Senha"
                        name={['settings', 'serviceInvoice', 'password']}
                        required
                      >
                        <Input maxLength={100} type="password" />
                      </FormItemWrap>
                    </ResCol>
                  </>
                )}
                {providerSettings.authentication === 'token' && (
                  <>
                    <ResCol
                      span={
                        !data.settings.serviceInvoice.useSeries &&
                        !data.settings.serviceInvoice.useBatchNumber
                          ? 16
                          : 16
                      }
                    >
                      <FormItemWrap
                        label="Token/Senha"
                        name={['settings', 'serviceInvoice', 'password']}
                      >
                        <Input maxLength={100} type="password" />
                      </FormItemWrap>
                    </ResCol>
                  </>
                )}
                <FormItemWrap
                  name={['settings', 'serviceInvoice', 'useRps']}
                  hidden
                >
                  <Input />
                </FormItemWrap>
              </Row>

              {providerSettings.provider === 'sigep' ? (
                <ResCol span={12}>
                  <FormItemWrap
                    name={['settings', 'serviceInvoice', 'authNumber']}
                    label="Chave privada"
                  >
                    <Input maxLength={40} type="password" />
                  </FormItemWrap>
                </ResCol>
              ) : null}

              <Tabs />
              <Tabs onChange={handleChangeTabs}>
                <TabPane tab="Produtor" key="producer" forceRender>
                  <Row>
                    {providerSettings.useFederalServiceCode ? (
                      <ResCol span={12}>
                        <FormItemWrap
                          label="Item da Lista de Serviço (LC 116/03)"
                          name={[
                            'producer',
                            'serviceInvoice',
                            'federalServiceCode'
                          ]}
                        >
                          <SearchValue
                            placeholder="Selecione um item"
                            drawerSize="50%"
                            title="Selecione o item da lista de serviço"
                            resource="products/federal-services"
                            maxLength={20}
                            columns={[
                              {
                                title: 'Código',
                                dataIndex: ['code'],
                                isKey: true
                              },
                              {
                                title: 'Descrição',
                                dataIndex: ['description']
                              }
                            ]}
                          />
                        </FormItemWrap>
                      </ResCol>
                    ) : null}
                    <ResCol span={12}>
                      <FormItemWrap
                        label={
                          providerSettings.provider === 'softplan'
                            ? 'CST'
                            : 'Código do serviço no munícipio'
                        }
                        name={['producer', 'serviceInvoice', 'cityServiceCode']}
                      >
                        <Input maxLength={20} />
                      </FormItemWrap>
                    </ResCol>
                  </Row>

                  <Row>
                    <ResCol span={12}>
                      <FormItemWrap
                        label="CNAE"
                        name={['producer', 'serviceInvoice', 'cnaeCode']}
                      >
                        <SearchValue
                          placeholder="Selecione um CNAE"
                          drawerSize="50%"
                          title="Selecione o CNAE"
                          resource="cnaes"
                          maxLength={15}
                          columns={[
                            {
                              title: 'Código',
                              dataIndex: 'code',
                              isKey: true,
                              width: '25%'
                            },
                            {
                              title: 'Descrição',
                              dataIndex: 'description',
                              width: '75%'
                            }
                          ]}
                        />
                      </FormItemWrap>
                    </ResCol>
                    <ResCol span={12}>
                      <FormItemWrap
                        label="Tributação"
                        name={['producer', 'serviceInvoice', 'taxationGroup']}
                      >
                        <RemoteSelectCrud
                          resource="taxation-groups"
                          params={{ type: 'serviceInvoice' }}
                          initialValues={{ type: 'serviceInvoice' }}
                          textPropName="name"
                          resourceTitle="Tributação"
                          editComponent={TaxationGroupEdit}
                        />
                      </FormItemWrap>
                    </ResCol>
                  </Row>
                </TabPane>
                <TabPane tab="Afiliado" key="affiliate" forceRender>
                  <Row>
                    <ResCol span={8}>
                      <FormItemWrap
                        label="Habilitar emissão de afiliado"
                        name={['affiliate', 'enabledAffiliateInvoices']}
                        valuePropName="checked"
                      >
                        <Switch onChange={handleChangeAffiliateEnabled} />
                      </FormItemWrap>
                    </ResCol>
                    <ResCol span={8}>
                      <FormItemWrap
                        label="Emitir notas automaticamente"
                        name={['affiliate', 'autoIssueInvoice']}
                        valuePropName="checked"
                      >
                        <Switch onChange={handleAutoIssueInvoice} />
                      </FormItemWrap>
                    </ResCol>
                    <ResCol span={8}>
                      <FormItemWrap
                        label="Competência"
                        name={['affiliate', 'effectiveMode']}
                      >
                        <Select disabled={!affiliateEnabled}>
                          <Select.Option value="inTheMonthOfOrder">
                            No mês das vendas
                          </Select.Option>
                          <Select.Option value="inTheMonthOfIssue">
                            No mês da emissão
                          </Select.Option>
                        </Select>
                      </FormItemWrap>
                    </ResCol>
                  </Row>
                  <FormItemWrap name={['affiliate', 'id']} hidden>
                    <Input />
                  </FormItemWrap>

                  <Row>
                    {providerSettings.useFederalServiceCode && (
                      <ResCol span={12}>
                        <FormItemWrap
                          label="Item da Lista de Serviço (LC 116/03)"
                          name={[
                            'affiliate',
                            'serviceInvoice',
                            'federalServiceCode'
                          ]}
                        >
                          <SearchValue
                            placeholder="Selecione um item"
                            drawerSize="50%"
                            title="Selecione o item da lista de serviço"
                            resource="products/federal-services"
                            disabled={!affiliateEnabled}
                            maxLength={20}
                            columns={[
                              {
                                title: 'Código',
                                dataIndex: ['code'],
                                isKey: true
                              },
                              {
                                title: 'Descrição',
                                dataIndex: ['description']
                              }
                            ]}
                          />
                        </FormItemWrap>
                      </ResCol>
                    )}
                    <ResCol span={12}>
                      <FormItemWrap
                        label={
                          providerSettings.provider === 'softplan'
                            ? 'CST'
                            : 'Código do serviço no munícipio'
                        }
                        name={[
                          'affiliate',
                          'serviceInvoice',
                          'cityServiceCode'
                        ]}
                      >
                        <Input maxLength={20} disabled={!affiliateEnabled} />
                      </FormItemWrap>
                    </ResCol>
                  </Row>
                  <Row>
                    <ResCol span={12}>
                      <FormItemWrap
                        label="CNAE"
                        name={['affiliate', 'serviceInvoice', 'cnaeCode']}
                      >
                        <SearchValue
                          placeholder="Selecione um CNAE"
                          drawerSize="50%"
                          title="Selecione o CNAE"
                          resource="cnaes"
                          maxLength={15}
                          disabled={!affiliateEnabled}
                          columns={[
                            {
                              title: 'Código',
                              dataIndex: 'code',
                              isKey: true,
                              width: '25%'
                            },
                            {
                              title: 'Descrição',
                              dataIndex: 'description',
                              width: '75%'
                            }
                          ]}
                        />
                      </FormItemWrap>
                    </ResCol>
                    <ResCol span={12}>
                      <FormItemWrap
                        label="Tributação"
                        name={['affiliate', 'serviceInvoice', 'taxationGroup']}
                      >
                        <RemoteSelectCrud
                          resource="taxation-groups"
                          params={{ type: 'serviceInvoice' }}
                          initialValues={{ type: 'serviceInvoice' }}
                          textPropName="name"
                          resourceTitle="Tributação"
                          editComponent={TaxationGroupEdit}
                        />
                      </FormItemWrap>
                    </ResCol>
                  </Row>
                </TabPane>
              </Tabs>
              <Divider orientation="left">Tributação aproximada</Divider>
              <Row>
                <ResCol span={6}>
                  <FormItemWrap
                    name={[
                      'settings',
                      'serviceInvoice',
                      'approximateTaxes',
                      'mode'
                    ]}
                    label="Modo de exibição"
                  >
                    <Select>
                      <Select.Option value={APPROXIMATETAXES_DISABLED}>
                        Não exibir
                      </Select.Option>
                      <Select.Option value={APPROXIMATETAXES_COMPLETE}>
                        {display[APPROXIMATETAXES_COMPLETE]}
                      </Select.Option>
                      <Select.Option value={APPROXIMATETAXES_SIMPLIFIED}>
                        {display[APPROXIMATETAXES_SIMPLIFIED]}
                      </Select.Option>
                    </Select>
                  </FormItemWrap>
                </ResCol>
                <ResCol span={6}>
                  <FormItemWrap
                    name={[
                      'settings',
                      'serviceInvoice',
                      'approximateTaxes',
                      'useIbpt'
                    ]}
                    label="Utilizar IBPT"
                  >
                    <Select
                      disabled={
                        approximateTaxes.mode === APPROXIMATETAXES_DISABLED
                      }
                    >
                      <Select.Option value>Sim</Select.Option>
                      <Select.Option value={false}>Não</Select.Option>
                    </Select>
                  </FormItemWrap>
                </ResCol>
                <ResCol span={4}>
                  <FormItemWrap
                    name={[
                      'settings',
                      'serviceInvoice',
                      'approximateTaxes',
                      'national'
                    ]}
                    label="Federal"
                  >
                    <InputPercent
                      disabled={
                        approximateTaxes.mode === APPROXIMATETAXES_DISABLED ||
                        approximateTaxes.useIbpt
                      }
                    />
                  </FormItemWrap>
                </ResCol>
                <ResCol span={4}>
                  <FormItemWrap
                    name={[
                      'settings',
                      'serviceInvoice',
                      'approximateTaxes',
                      'state'
                    ]}
                    label="Estadual"
                  >
                    <InputPercent
                      disabled={
                        approximateTaxes.mode === APPROXIMATETAXES_DISABLED ||
                        approximateTaxes.useIbpt
                      }
                    />
                  </FormItemWrap>
                </ResCol>
                <ResCol span={4}>
                  <FormItemWrap
                    name={[
                      'settings',
                      'serviceInvoice',
                      'approximateTaxes',
                      'municipal'
                    ]}
                    label="Municipal"
                  >
                    <InputPercent
                      disabled={
                        approximateTaxes.mode === APPROXIMATETAXES_DISABLED ||
                        approximateTaxes.useIbpt
                      }
                    />
                  </FormItemWrap>
                </ResCol>
              </Row>
              <Form.Item
                name={[
                  'settings',
                  'serviceInvoice',
                  'approximateTaxes',
                  'imported'
                ]}
                hidden
              />
              <Form.Item
                name={[
                  'settings',
                  'serviceInvoice',
                  'approximateTaxes',
                  'expiresAt'
                ]}
                hidden
              />
              <Form.Item
                name={[
                  'settings',
                  'serviceInvoice',
                  'approximateTaxes',
                  'version'
                ]}
                hidden
              />
              {tab === 'producer' && (
                <>
                  {(isAdmin() ||
                    (company.taxRegime === 'simplesNacional' &&
                      issueType === 'annfs')) && (
                    <Divider orientation="left">Outras opções</Divider>
                  )}
                  <Row>
                    {isAdmin() && (
                      <ResCol span={11}>
                        <Form.Item
                          label="Modo de dedução do Custo"
                          name={['settings', 'serviceInvoice', 'deductionMode']}
                        >
                          <Select>
                            <Select.Option value="none">Nenhum</Select.Option>
                            <Select.Option value="inInvoiceAmount">
                              No valor total da nota
                            </Select.Option>
                            <Select.Option value="inNetValue">
                              No valor líquido da nota
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </ResCol>
                    )}
                    {company.taxRegime === 'simplesNacional' &&
                    issueType === 'annfs' ? (
                      <ResCol span={13}>
                        <FormItemWrap
                          label="Regime de Apuração dos Tributos do Simples Nacional"
                          name={['settings', 'simplesNacionalTaxRegime']}
                        >
                          <Select allowClear dropdownMatchSelectWidth={false}>
                            <Select.Option value="1">
                              Regime de apuração dos tributos federais e
                              municipal pelo SN
                            </Select.Option>
                            <Select.Option value="2">
                              Regime de apuração dos tributos federais pelo SN e
                              o ISSQN pela NFS-e conforme respectiva legislação
                              municipal do tributo
                            </Select.Option>
                            <Select.Option value="3">
                              Regime de apuração dos tributos federais e
                              municipal pela NFS-e conforme respectivas
                              legilações federal e municipal de cada tributo
                            </Select.Option>
                          </Select>
                        </FormItemWrap>
                      </ResCol>
                    ) : null}
                  </Row>
                  {isAdmin() && (
                    <>
                      <Row>
                        <ResCol span={18}>
                          <FormItemWrap
                            label="Cálculo do ISS"
                            name={[
                              'settings',
                              'serviceInvoice',
                              'issCalculationMode'
                            ]}
                          >
                            <Checkbox.Group
                              options={issCalculationModes}
                              value={checkedCalculationMode}
                              onChange={handleChangeCalculationMode}
                            />
                          </FormItemWrap>
                        </ResCol>
                      </Row>
                      <ResCol span={14}>
                        <FormItemWrap
                          name={[
                            'settings',
                            'serviceInvoice',
                            'supressReceiverEmailAddress'
                          ]}
                          label="Não enviar o e-mail do tomador na NFS-e"
                          valuePropName="checked"
                        >
                          <Switch />
                        </FormItemWrap>
                      </ResCol>
                    </>
                  )}
                </>
              )}
            </TabPane>
          )}
          <TabPane tab="NF-e" key="nfe" forceRender>
            <Row>
              <ResCol span={8}>
                <FormItemWrap
                  name={['settings', 'productInvoice', 'environmentType']}
                  label="Ambiente"
                >
                  <Select>
                    <Select.Option value="production">Produção</Select.Option>
                    <Select.Option value="development">
                      Homologação
                    </Select.Option>
                  </Select>
                </FormItemWrap>
              </ResCol>
              <ResCol span={8}>
                <FormItemWrap
                  name={['settings', 'productInvoice', 'series']}
                  label="Série"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={99999999999}
                  />
                </FormItemWrap>
              </ResCol>
              <ResCol span={8}>
                <FormItemWrap
                  name={['settings', 'productInvoice', 'nextNumber']}
                  label="Número da próxima NF-e"
                >
                  <InputNumber style={{ width: '100%' }} />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={24}>
                <FormItemWrap
                  name={['settings', 'useAutomaticDevolutionInvoice']}
                  label="Emitir nota fiscal de Devolução quando o prazo de cancelamento for ultrapassado?"
                  valuePropName="checked"
                  tooltip="Ao habilitar essa opção, será realizada a emissão de uma nota fiscal com a natureza de Devolução, quando houver cancelamento de uma venda com NF-e autorizada, após o prazo de cancelamento da nota fiscal no estado de origem."
                >
                  <Switch />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Tabs>
              <TabPane tab="Produtor" key="producer" forceRender>
                <Row>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="Código de Barras"
                      name={['producer', 'productInvoice', 'gtinCode']}
                    >
                      <Input maxLength={14} />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="Unidade Comercial"
                      name={['producer', 'productInvoice', 'unitOfMeasurement']}
                      initialValue="UN"
                    >
                      <ProductUnitOfMeasurement />
                    </FormItemWrap>
                  </ResCol>
                </Row>

                <Row>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="Unidade Tributável"
                      name={[
                        'producer',
                        'productInvoice',
                        'taxUnitOfMeasurement'
                      ]}
                      initialValue="UN"
                    >
                      <ProductUnitOfMeasurement />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="Unidade Tributável (Exportação)"
                      name={[
                        'producer',
                        'productInvoice',
                        'exportationTaxUnitOfMeasurement'
                      ]}
                    >
                      <ProductUnitOfMeasurement />
                    </FormItemWrap>
                  </ResCol>
                </Row>

                <Row>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="CFOP Interno"
                      name={['producer', 'productInvoice', 'cfopIn']}
                    >
                      <CfopSelect />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="CFOP Internacional"
                      name={['producer', 'productInvoice', 'cfopInternational']}
                    >
                      <CfopSelect />
                    </FormItemWrap>
                  </ResCol>
                </Row>

                <Row>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="CFOP Interestadual (Não contribuinte)"
                      name={['producer', 'productInvoice', 'cfopInterstate']}
                    >
                      <CfopSelect />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="CFOP Interestadual (Contribuinte)"
                      name={[
                        'producer',
                        'productInvoice',
                        'cfopTaxpayerInterstate'
                      ]}
                    >
                      <CfopSelect />
                    </FormItemWrap>
                  </ResCol>
                </Row>

                <Row>
                  <ResCol span={12}>
                    <FormItemWrap
                      label="NCM"
                      name={['producer', 'productInvoice', 'ncm']}
                    >
                      <SearchValue
                        drawerSize="50%"
                        title="NCM"
                        resource="ncms"
                        columns={[
                          {
                            title: 'Código',
                            dataIndex: 'code',
                            isKey: true,
                            width: '25%'
                          },
                          {
                            title: 'Descrição',
                            dataIndex: 'description',
                            width: '75%'
                          }
                        ]}
                      />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    {false && (
                      <FormItemWrap
                        label="CEST"
                        name={['producer', 'productInvoice', 'cest']}
                      >
                        <SearchValue
                          drawerSize="50%"
                          title="CEST"
                          resource="cests"
                          columns={[
                            {
                              title: 'Código',
                              dataIndex: 'code',
                              isKey: true,
                              width: '25%'
                            },
                            {
                              title: 'Descrição',
                              dataIndex: 'description',
                              width: '75%'
                            }
                          ]}
                        />
                      </FormItemWrap>
                    )}
                    <FormItemWrap
                      label="Tributação"
                      name={['producer', 'productInvoice', 'taxationGroup']}
                    >
                      <RemoteSelectCrud
                        resource="taxation-groups"
                        params={{ type: 'productInvoice' }}
                        initialValues={{ type: 'productInvoice' }}
                        textPropName="name"
                        resourceTitle="Tributação"
                        editComponent={TaxationGroupEdit}
                      />
                    </FormItemWrap>
                  </ResCol>
                </Row>

                <Row>
                  <ResCol span={24}>
                    <FormItemWrap name={['producer', 'id']} hidden>
                      <Input />
                    </FormItemWrap>
                    <FormItemWrap
                      label="Informações adicionais"
                      name={[
                        'producer',
                        'productInvoice',
                        'additionalInformation'
                      ]}
                      whitespace
                    >
                      <Input.TextArea maxLength={2000} rows={3} />
                    </FormItemWrap>
                  </ResCol>
                </Row>
              </TabPane>
            </Tabs>
          </TabPane>

          <TabPane tab="Geral" key="settings" forceRender>
            <Divider className="gx-mt-0" orientation="left">
              Endereço
            </Divider>
            <Row>
              <ResCol>
                <FormItemWrap
                  name={['settings', 'addressResolutionMode']}
                  label="O que fazer ao emitir uma nota fiscal onde o cliente não está com endereço informado?"
                >
                  <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    style={{ marginTop: '6px', maxWidth: '320px' }}
                  >
                    <Select.Option value="none">
                      Emitir sem o endereço do cliente
                    </Select.Option>
                    <Select.Option value="useCompanyAddress">
                      Emitir com o endereço da empresa
                    </Select.Option>
                    <Select.Option value="useDefaultAddress">
                      Emitir com um endereço padrão
                    </Select.Option>
                    <Select.Option value="useForeignAddress">
                      Emitir com endereço estrangeiro
                    </Select.Option>
                  </Select>
                </FormItemWrap>
              </ResCol>
              <ResCol>
                {selectedOption === 'useDefaultAddress' ? (
                  <AddressV2 fieldNames={['settings', 'defaultAddress']} />
                ) : null}
                {selectedOption === 'useForeignAddress' ? (
                  <AddressForForeign
                    required={false}
                    requiredCountry
                    fieldNames={['settings', 'defaultAddress']}
                  />
                ) : null}
                {selectedOption !== 'none' ? (
                  <>
                    <FormItemWrap
                      name={['settings', 'useDefaultAddressWhenIncomplete']}
                      valuePropName="checked"
                      label={
                        <>
                          Utilizar este endereço em clientes com endereço
                          incompleto
                          <TooltipInfoIcon
                            title={
                              <>
                                Este endereço será utilizado na emissão de notas
                                quando o endereço do cliente estiver incompleto.
                              </>
                            }
                            placement="rightTop"
                          />
                        </>
                      }
                    >
                      <Switch />
                    </FormItemWrap>
                    <Alert
                      message={
                        'Para utilização do endereço da empresa ou endereço padrão na nota fiscal, é primordial confirmar com sua contabilidade se este recurso não trará incidentes fiscais à sua empresa.'
                      }
                      showIcon
                      type="warning"
                    />
                  </>
                ) : null}
              </ResCol>
            </Row>
            {isAdmin() && (
              <>
                <Divider orientation="left">Outras opções</Divider>
                <Row>
                  <ResCol>
                    <FormItemWrap
                      name={['settings', 'allowMultipleInvoiceModelsPerOrder']}
                      label="Permitir produtos com modelos de nota fiscal diferentes para a mesma venda"
                      valuePropName="checked"
                    >
                      <Switch />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol>
                    <FormItemWrap
                      name={['settings', 'useInvoiceNumeringPerProduct']}
                      label="Habilitar numeração de nota fiscal por produto"
                      valuePropName="checked"
                    >
                      <Switch />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol>
                    <FormItemWrap
                      name={['settings', 'groupMonthlyClosingBySeries']}
                      label="Agrupar fechamento mensal por série da nota fiscal"
                      valuePropName="checked"
                    >
                      <Switch />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={12}>
                    <FormItemWrap
                      name={['settings', 'invoiceIssuanceLimit']}
                      label="Limite de emissão de notas fiscais por ciclo"
                    >
                      <InputNumber
                        style={{ width: '45%' }}
                        min={0}
                        max={99999999999}
                      />
                    </FormItemWrap>
                  </ResCol>

                  <ResCol span={14}>
                    <FormItemWrap
                      name={['settings', 'productKitEnabled']}
                      label="Habilitar kit de produtos?"
                      valuePropName="checked"
                    >
                      <Switch />
                    </FormItemWrap>
                  </ResCol>
                </Row>
              </>
            )}
          </TabPane>
        </Tabs>
      </Form>
    </DrawerEdit>
  );
};
export default ResourceEdit.create(CompanySettings, {
  resource: 'companies',
  currentAttr: 'editingSettings'
});
